* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    scroll-behavior: smooth;
}

body {
    background: #f1f2f6;
}


/*section-1*/

.section-1 {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-1 .video-1 {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.section-1 .content-1 {
    position: relative;
    left: 0;
    width: 100%;
    height: 100%;
    color: #f1f1f1;
    justify-content: center;
    text-align: center;
    top: 0;
    background: #0303037e;
}

.content-1 img {
    margin-top: 200px;
    width: 300px;
    height: 300px;
}


/*section-2*/

.section-2 {
    background: #f1f2f6;
}

.about-heading h1 {
    margin-top: 30px;
    justify-content: center;
    text-align: center;
    font-family: Gabriola;
    font-size: 50px;
}

.hrline-1 {
    justify-content: center;
    text-align: center;
    width: 200px;
    border-radius: 8px;
    padding: 1px;
    background: black;
}

.section-2 .card-1 {
    margin-top: 50px;
    border: none;
    text-decoration: none;
    margin-bottom: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.card-body-1 {
    justify-content: center;
    text-align: center;
    padding: 40px;
}

.card-body-1 p {
    text-align: left;
    color: #666666;
    line-height: 30px;
    font-size: 22px;
    word-spacing: 8px;
}

.activity {
    text-align: left;
    color: red;
}

.card-body-1 li {
    text-align: left;
    color: #666666;
    font-size: 20px;
    margin-left: 20px;
    letter-spacing: 1px;
    word-spacing: 3px;
    line-height: 30px;
}

.card-body-1 span {
    color: red;
    font-size: 30px;
}


/*section-3*/

.section-3 {
    margin: 0 auto;
    width: 100%;
}

.container-2 {
    margin-top: 30px;
    margin-bottom: 20px;
}

.video-2 {
    width: 100%;
    height: auto;
}

.heading-3 h1 {
    margin-top: 40px;
    justify-content: center;
    text-align: center;
    font-family: Gabriola;
    font-size: 50px;
}

.hrline-3 {
    justify-content: center;
    text-align: center;
    width: 130px;
    border-radius: 8px;
    padding: 1px;
    color: rgb(0, 0, 0);
    background: black;
}


/*section-4*/

.section-4 {
    background: #f1f2f6;
}

.container-3 {
    margin-top: 70px;
    margin-bottom: 30px;
}

.heading-2 h1 {
    margin-top: 40px;
    justify-content: center;
    text-align: center;
    font-family: Gabriola;
    font-size: 50px;
}

.hrline-2 {
    justify-content: center;
    text-align: center;
    width: 130px;
    border-radius: 8px;
    padding: 1px;
    color: rgb(0, 0, 0);
    background: black;
}

.section-4 .card-3 {
    margin-top: 50px;
    border: none;
    text-decoration: none;
    margin-bottom: 10px;
    height: 200px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.card-body-3 {
    justify-content: center;
    text-align: center;
    padding: 20px;
    background: white;
}

.carousel-item p {
    margin-top: 30px;
    font-size: 20px;
}


/*Responsive*/

@media (max-width: 800px) {
    .card-body-1 {
        padding: 20px;
    }
    .card-body-1 p {
        font-size: 20px;
    }
}