    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Montserrat', sans-serif;
        scroll-behavior: smooth;
    }
    
    body {
        background: #f1f2f6;
    }
    
    .about-color {
        height: 350px;
        background: #c31432;
        /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #240b36, #c31432);
        /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #240b36, #c31432);
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
    
    .container-setting {
        margin-top: -250px;
    }
    
    .about-heading-1 h1 {
        color: #ffffff;
        margin-top: 40px;
        justify-content: center;
        text-align: center;
        font-family: Gabriola;
        font-size: 50px;
    }
    
    .hrline {
        justify-content: center;
        text-align: center;
        width: 200px;
        border-radius: 8px;
        padding: 1px;
        color: white;
    }
    
    .card-1 {
        margin-top: 50px;
        border: none;
        text-decoration: none;
        margin-bottom: 40px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    
    .card-body-1 {
        justify-content: center;
        text-align: center;
        padding: 40px;
    }
    
    .card-body-1 p {
        text-align: left;
        color: #666666;
        line-height: 30px;
        font-size: 22px;
        word-spacing: 8px;
    }
    
    .activity {
        text-align: left;
        color: red;
    }
    
    .card-body-1 li {
        text-align: left;
        color: #666666;
        font-size: 20px;
        margin-left: 20px;
        letter-spacing: 1px;
        word-spacing: 3px;
        line-height: 30px;
    }
    
    .card-body-1 span {
        color: red;
        font-size: 30px;
    }