* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    scroll-behavior: smooth;
}


/*scrollbar design*/

body::-webkit-scrollbar {
    width: 7px;
}

body::-webkit-scrollbar-track {
    background: #e6e6e6;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #3c6382;
}

.navbar {
    background: white;
    height: 70px;
    /*background: #0F2027;
    
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);
  
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027);
    
    z-index: 1;
    transition: top 0.3s;*/
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.navbar-brand img {
    width: 60px;
    height: 60px;
}

.navbar-nav {
    float: left;
}

.responsive-navigation {
    display: none;
}

.offcanvas-title {
    color: rgb(0, 0, 0);
}

.btn-close {
    color: white;
}

.offcanvas-start {
    background:white;
    width: 320px;
}

.bi-list {
    font-size: 30px;
    padding: 0;
    margin: 0;
    color: rgb(0, 0, 0);
    z-index: 1;
   
}

.nav-item {
    padding: 8px;
}

.nav-link {
    color: rgb(10, 4, 4);
    font-size: 18px;
}
.card-body{
    border: none;
    
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(0, 0, 0, 0.05) 0px 2px 16px 0px;
}
.nav-link:hover,
.nav-link:active {
    color: #a6a6a677;
    transition: all .5s ease;
}

.dropdown-menu {
    text-decoration: none;
    border: none;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@media (max-width: 900px) {
    #myBtn:hover {
        background-color: #b71540;
    }
}

@media (max-width: 990px) {
    .responsive-navigation {
        display: block;
    }
.navbar {
  
    height: 80px;
    }
    .nav-item {
        padding: 0px;
        padding-left: 15px;
    }
}